import React, { useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CustomDateTimePicker } from "./FormField";
import DateFnsUtils from "@date-io/date-fns";
import { FaRegCalendarAlt } from "react-icons/fa";

const styles = {
  text: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "rgb(64, 64, 64)",
  },
  input: {
    width: "99%",
    marginBottom: 10,
  },
};

const DateTimeField = ({
  onChange,
  allowedHours,
  errorMessage,
  language,
  disableDay,
  className,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const validateDate = (date) => {
    const selectedDate = new Date(date);
    const currentTime = new Date();
    if (selectedDate < new Date()) {
      onChange(false, "* Time can't be in past", date);
      return;
    }
    const getTimeDiff = diff_in_minutes(selectedDate, currentTime);
    const helperText = getTimeDiff < 30 ? false : true;
    if (!helperText) {
      onChange(helperText, "Atleast 30 mins from now", date);
      return;
    }
    onChange(true, "Success", date);
  };

  const diff_in_minutes = (selectedDate, currentTime) => {
    var diff = (selectedDate.getTime() - currentTime.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const enableFuture = () => {
    let date = new Date();
    date.setDate(date.getDate() + 45);
    return date;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomDateTimePicker
        required
        disablePast
        inputVariant="outlined"
        maxDate={enableFuture()}
        onChange={(date) => validateDate(date)}
        style={{ ...styles.input }}
        format={"dd - MMM - yyyy hh:mm a"}
        InputLabelProps={{
          style: {
            color: "rgb(69, 27, 171)",
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: 300,
          },
        }}
        variant="outlined"
        className={`datetime-picker text-field`}
        {...rest}
        onOpen={() => {
          setIsOpen(true);
        }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onAccept={() => {
          setIsOpen(false);
        }}
        keyboardIcon={<FaRegCalendarAlt fill="#bdb9b9" />}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeField;
